/* Styles for StatistikaTable component */
.statistika-table1 {
  display: flex;
  flex-direction: column;
}
.statistika-table2 {
margin-top: 25px;
}

/* Rows with background */
.question1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
}

/* First cell */
.title1 {
  flex: 4;
  background-color: white;
  padding-left: 26px;
  font-weight: 500;
  padding: 10px;
  border-radius: 5px;
  }
  .title1 p{
margin:unset;
padding: 5px;
  }
.title2{
  flex:4
}



/* Second cell */
.answers2:nth-child(2) {
  flex: 1;
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 10px;
  margin-left: 10px;
  background-color: white;
  border-radius: 5px;
}

/* Third cell */
.answers1:nth-child(3) {
  flex: 1;
  display: flex;
  justify-content: center; 
  align-items: center; 
  padding: 10px;
  background-color: white;
  border-radius: 5px;
}



/* Third cell Darbuotojai */
.answers2:nth-child(3) {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 5px;
}

/* Status styles */
.result-atsakymai1 {
  font-size: 16px;
  text-align: center;
  margin: 0;
  /* padding: 5px; */
  border-radius: 5px;
  color: #0b0101;

}

/* Status styles for green */
.result-atsakymai1.green1 {
  color: green;
  font-weight: 500;
}

/* Status styles for red*/
.result-atsakymai1.red1 {
  color: red;
  font-weight: 500;
}



/* Space between columns */
.answers1 {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: 10px;
  
}



/* Responsive */
@media (max-width: 700px) {
  .statistika-table2 {
    display: block;
    overflow-x: auto;
    min-width: 627px;
  }
  .bgBlue,.statistika-table2   {
    overflow-x: auto;
    }
    .title1 {
      flex: 1 1;
      background-color: white;
      padding-left: 26px;
      font-weight: 500;
      }
      .title2{
        flex:1
      }
}

.ball1{
  padding: 5px;
  font-weight: 500;
}