@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@400;500;600;700;800&family=Roboto:wght@100;300;400;500;700;900&display=swap");

:root {
  --main-background: #fff;
  --main-backgroundDark: #fbfbfb;
  --main-color-ours: #665ae1;
  --secondary-background: #e8eefe;
  --third-background: #f3f6ff;
  --main-choose: #f4b050;
  --main-inactive: grey;
  --main-white: #f0eee7;
  --place-holder-clr: grey;
  --main-nepriskirtas: rgb(158, 6, 6);
  --main-priskirtas: #1b792a;
  --main-apmoketa: rgb(33, 104, 15);
  font-family: Inter, Roboto, Poppins !important;
}

body {
  background: var(--main-background);
  font-family: Inter, Roboto;
}



body #creatorElement {
  /* SurveyJS Creator V1 */
  --primary-color: #7f7ff0;
  --secondary-color: #b31a78;
  --primary-text-color: #4a4a4a;
  --secondary-text-color: #a7a7a7;
  --inverted-text-color: #ffffff;
  --primary-hover-color: #6fe06f;
  --selection-border-color: #1ab394;
  --primary-icon-color: #3d4d5d;
  --primary-bg-color: #f8f8f8;
  --secondary-bg-color: #f4f4f4;
  --primary-border-color: #e7eaec;
  --secondary-border-color: #ddd;
  --error-color: #ed5565;
}

#root {
  /* display: -webkit-box; */
  /* display: -ms-flexbox; */
  /* display: flex; */
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  /* align-items: center; */
  /* min-width: 100vw; */
  min-height: 100vh;
}

.btn-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  margin: 20px 0;
}

.container {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  flex-direction: column;
}

/* //modal */
.modalImg img {
  max-width: 100px;
}

.width100 {
  width: 100%;
}

.manyImg {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.manyImg > div > div {
  /* padding: 10px 10px 10px 0; */
  /* border: 1px solid black; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
}

.modalTexts label {
  -webkit-box-align: unset;
  -ms-flex-align: unset;
  align-items: unset;
}

.modalTexts textarea {
  min-height: 60px;
}

.dataGrid,
.dataGrid * {
  border: none !important;
}

.primary {
  background-color: #dc643e !important;
  color: var(--main-background);
  font-size: 15px;
  font-weight: bold;
}

.btn {
  padding: 10px 20px !important;
  border-radius: 50px !important;
  width: 100%;
}

.btn:hover {
  cursor: pointer;
}

.modal.nuotraukos.hidden {
  display: none;
}

.hidden {
  display: none !important;
}

.spaceAround {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.spaceBetween {
  justify-content: space-between;
}

.alignCenter {
  align-items: center;
}

.txtAlignCenter {
  text-align: center !important;
}

.pointer {
  cursor: pointer !important;
}

.center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.margin {
  margin: 5px !important;
}

.name {
  font-size: 24px;
  font-weight: 700;
  font-family: Poppins;
  margin: unset;
}

.capitalize{
  text-transform: capitalize!important;
}

.isDidz{
  text-transform: none!important;
}

.displayNone{
  display: none!important;
}




/* .btn.disabled {
  background-color: var(--main-inactive);
} */

/* paveiksliukai galima bus pakeisti veliau */
.deleteImg {
  height: 50px;
  width: 20px;
  /*! object-fit: scale-down; */
}

/* img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
} */
/* end of paveikliukai */

.Routai {
  padding: 0 40px;
}

/* .routaiCont {
  display: flex;
  width: 100%;
  justify-content: center;
} */

.Formos {
  display: flex;
  flex-direction: column;
}

.Creator {
  display: flex;
  flex-direction: column;
}

.Statiska {
  display: flex;
  flex-direction: column;
}

.Vartotojai {
  display: flex;
  flex-direction: column;
}

/* Component/Select */
.tendencijos {
  padding: 5px 0;
}

.tendencijosBorderBot {
  border-bottom: 1px solid #8080806b;
}

.standartPadding {
  padding: 0 6vw;
}

.modal3 {
  width: 100% !important;
  /* max-width: 90vw !important; */
  margin: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  height: 100%;
  background-color: var(--third-background);
}

.centerContainer1 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 30px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* background-color: var(--main-color-ours); */
  background-color: var(--main-background);
  border-radius: 4px;
}

.modalBtnCont {
  padding: 20px;
}

.modalBtn {
  /* background-color: var(--main-background)!important; */
  /* color: var(--main-color-ours)!important; */
}

#demo-simple-select-label {
  z-index: 0;
}

.modalTxt {
  /* color: var(--main-background); */
  font-size: 18px;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled {
  z-index: 0;
}