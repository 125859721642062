.qeastionPage {
  margin: 20px 0;
}
.bgBlue {
  background-color: var(--secondary-background) !important;
}
.pageName {
  display: flex;
}

.pageContent > div {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 320px;
  grid-gap: 10px;
}

.error path {
  fill: red;
}
.error {
  color: red;
}
.bgBlue {
  background-color: --var(--secondary-bg-color);
}
.flexToSides {
  justify-content: space-between;
}
/* drawer */
.drawer.right
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorRight {
  max-width: 350px;
}
.filterPadding {
  padding: 0 20px;
}
/* tendecijos */
.TendencijosTable {
  padding: 10px 0;
}
.pageName > div {
  padding: 20px 5px;
}
.question .title {
  background: var(--body-background-color);
  padding: 10px 15px;
  border-radius: 4px;
  margin: 3px 0;
  min-width: 320px;
}
.pageTitleMetai.desktop {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  font-weight: 600;
}
.question .anwsers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 3px 0px;
  color: var(--main-color-ours);
  border-radius: 4px;
}

.question .anwsers > div {
  background: white;
  /* min-width: 300px; */
  /* min-height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 0 10px;
  height: 100%;
  min-width: 40px;
}
.verticalCenter {
  align-items: center;
}
.pageTitle {
  font-weight: 600;
}
.bendras .title {
  font-weight: 600;
}
.metai {
  padding: 20px 0 0px 0;
  padding-top: 20px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  margin: 0;
}
.apklausosPavadinimas {
  margin: 0;
}
.borderBot {
  border-bottom: 1px solid var(--main-color-ours);
}
.exportRow {
  margin: 10px 0;
  padding: 10px 0;
}
.standartPadding {
  padding-top: 20px;
}
.RezultataiTable .pageContent > div {
  display: flex;
}
/* .RezultataiTable .MuiAccordionDetails-root {
  background-color: var(--body-background-color);
} */
.RezultataiTable .MuiAccordion-root {
  width: 100%;
}
.RezultataiTable .MuiCollapse-root {
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.Balas .text {
  font-weight: 600;
  color: var(--main-color-ours);
}
.Balas > div:not(.text) {
  padding: 8px 16px;
  font-weight: 600;
  background: var(--main-background);
  border-radius: 4px;
  cursor: pointer;
}
.Balas .rezultataiAts {
  width: 32px;
  text-align: center;
}
/* .RezultataiTable .sa-question__title {
  display: none;
} */
@media (min-width: 768px) {
  .mobile {
    display: none;
  }
}

@media (max-width: 768px) {
  .desktop {
    display: none !important;
  }

  .pageContent > div {
    display: flex;
    flex-direction: column;
  }
  .question .anwsers > div {
    flex-direction: column;
    background: var(--secondary-bg-color);
    min-width: unset;
    min-height: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: unset;
  }
  .question .anwsers > div > div:not(.mobile) {
    background: var(--body-background-color);
    margin: 2px 10px 2px 0;
    padding: 10px 30px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    color: var(--main-color-ours);
  }
  .question .anwsers > div > div.mobile {
    font-weight: 600;
    color: black;
  }
  .question .title {
    background: var(--secondary-bg-color);
    /* padding: 0; */
  }
  .pageName > div {
    padding: 20px 0;
  }
  .question .anwsers {
    justify-content: flex-start;
    margin: 2px 10px 2px 0;
    padding-right: 20px;
  }
}

/*Nuo čia*/

.question .anwsers {
  background: white;
}

.question .title {
  background-color: white;
}

.pageContent > div {
  grid-template-columns: 70%;
}
.sd-question__header.sd-element__header.sd-question__header--location-top.sd-element__header--location-top {
  display: flex !important;
  flex-direction: column-reverse !important;
}
.sd-description.sd-question__description {
  color: black !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  padding-bottom: 11px !important;
}
.sd-element--complex.sd-element--with-frame
  > .sd-element__header--location-top:after {
  bottom: -75px !important;
}
.sd-question__title {
  padding: 0px !important;
}
.sd-table__cell--actions:not(.sd-table__cell--vertical):first-of-type,
.sd-table__cell--empty:first-of-type,
.sd-table__cell--row-text:first-of-type,
.sd-matrix__cell:first-of-type:first-of-type,
.sd-matrix tr > td:first-of-type:first-of-type {
  left: calc(-1 * var(--base-unit, 0px)) !important;
}
.sd-table__cell {
  line-height: calc(5 * var(--base-unit, 8px)) !important;
  padding: calc(1 * var(--base-unit, 6px)) !important;
}

.sd-btn {
  color: var(--primary, #665ae1) !important;
}
.sd-btn:not(:disabled):hover,
.sd-btn:not(:disabled):focus {
  box-shadow: 0 0 0 2px var(--primary, #665ae1) !important;
}

.sd-item--checked .sd-item__decorator {
  background: var(--primary, #665ae1) !important
                            ;
}

/* .sd-body.sd-body--responsive .sd-body__timer,
.sd-body.sd-body--responsive .sd-body__navigation {
  position: absolute !important;
  bottom: 0 !important;
} */
.sd-table thead th {
  line-height: calc(5 * var(--base-unit, 6px)) !important;
}
.sd-body__progress--top {
  position: sticky;
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  z-index: 50;
  margin-top: 11px !important;
}
.sd-body--responsive .sd-page {
  padding: calc(5 * var(--base-unit, 0px)) var(--sd-page-vertical-padding)
    calc(2 * var(--base-unit, 8px)) !important
                                  ;
}
:focus-visible {
  outline: red auto 1px;
}
.sd-item__control:focus + .sd-item__decorator,
.sd-item--allowhover .sd-selectbase__label:hover .sd-item__decorator {
  box-shadow: 0 0 0 1px var(--primary, #665ae1) !important;
  outline: none;
}
.sd-radio--checked .sd-radio__decorator:after {
  background-color: var(--primary-foreground, #fff) !important;
}

.routaiCont .sd-table {
  display: block;
  max-height: 440px;
  min-width: 100%;
  position: relative;
  overflow: auto;
}

.routaiCont .sd-question__content {
  display: block;
  /* max-height: 400px; */
  position: relative;
}

.routaiCont .sd-table thead {
  position: -webkit-sticky !important;
  position: -webkit-sticky !important;
  position: -moz-sticky !important;
  position: -ms-sticky !important;
  position: -o-sticky !important;
  position: sticky !important;
  top: 0 !important;
  background-color: white;
  z-index: 1000;
}

table.sd-table--alternate-rows > tbody > tr > td:first-child {
  flex: 100%;
}

table.sd-table--alternate-rows > tbody > tr {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100% !important;
}

table.sd-table--alternate-rows > tbody {
  display: flex;
  flex-direction: column;
  width: 100%;
}

table.sd-table--alternate-rows > thead > tr > td {
  display: none;
}
/* 1/16 */
.stickyBot {
  position: sticky;
  bottom: 0;
}
.bgWhite {
  background-color: white;
}
.filterBtnPadding {
  padding: 10px;
}
.fullWidth {
  width: 100%;
}
.fullWidth
  .MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary.MuiButton-sizeMedium.MuiButton-containedSizeMedium.css-pae4nh-MuiButtonBase-root-MuiButton-root {
  width: 100%;
}
.title.noBg {
  background: none;
}
.standartPadding.bgBlue.tendRezMargin {
  min-height: 100vh;
}
/* kalboms */
.prisijungimoKalbos {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 99;
}
.prisijungimoKalbos .MuiBox-root {
  min-width: none;
}
.atsakmyasRezultatasPilkas,
.atsakmyasRezultatasPilkas,
.benrasBalaiCont .pilkas {
  color: #242424;
  font-weight: 300;
  margin-left: 2px;
}
/* Paulius */
.RezultataiTable .MuiAccordionDetails-root.AccordionDetalilsCont {
  /* padding: 50px 60px !important; */
}
/* .sa-toolbar {
  display: none;
} */

/* .modebar-container {
  display: none;
} */

.qeastionPage {
  margin: 0px !important;
}
.apklausosPavadinimas {
  margin: 10px 0px 25px;
}
.pageTitle {
  font-weight: 600;
  font-size: 18px;
}
.tendencijosBorderBot {
  border-bottom: unset;
}
.MuiAccordionDetails-root {
  padding: 22px 16px 16px !important;
}

.apklausaKalbos.formoje.pasibaiges {
  top: 50%;
  left: 50%;
  display: flex;
  transform: translate(-50%, -50%);
  width: 1000px;
  height: 100vh;
  background: white;
  justify-content: center;
  align-items: center;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 2;
}
/* .tabulator-row > :first-child,
.tabulator-headers > :first-child {
  background: red;
  display: none !important;
} */
.sd-body--empty span {
  visibility: hidden;
}

.diskriminacijaTextPrieKlausimo {
  overflow-wrap: normal;
  flex-flow: nowrap;
  display: flex;
  font-size: 14px;
  color: grey;
}
.tabulator {
  display: none;
}

.sa-table__header-extensions {
  display: none;
}

.sa-tabulator__pagination-container {
  display: none;
}
.sa-tabulator__header {
  padding: 0px !important;
  background-color: unset !important;
  overflow: auto;
}
.sa-tabulator__downloads-bar .sa-table__btn {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: #fff;
  background-color: #665ae1;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  margin: 0 10px;
  color: var(--main-color-ours) !important;
  background-color: white !important;
  margin: 10px;
}
.sa-tabulator__downloads-bar .sa-table__btn:hover {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: unset;
}
.pdfIhtmlButton.singleQuestion {
  position: static;
  top: 0;
  /* float: left; */
  margin-left: -20px;
  margin-bottom: 0;
}
