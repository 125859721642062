.prisijungimoLangas {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  justify-content: flex-end;
  background: lightblue url('../burbulai.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}




.lentele {
  background-color: white;
  padding: 0 30px 0 30px;
  width: 50%;
  min-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  position: relative;
}


.lenteleVidus {
  width: 32vw;
  height: 600px;
  overflow: auto;
  display: flex;
  flex-direction: column;
}

.lentele h2 {
  color: black;
  text-align: left;
}


.prisijungimasImg {
  position: absolute;
}

.prisijungimasImg img {
  height: 100vh;
  width: 100vw;
  object-fit: cover;
}

.logReg {
  border-bottom: 2px solid var(--main-color-ours);
  width: fit-content;
  padding: 0 10px;
  justify-content: center;
  align-items: center;
}

.logRegTxt {
  text-transform: capitalize !important;

}

.logRegCont {
  display: flex;
  flex-direction: row;
}

.mainTxt {
  font-family: Poppins;
  font-weight: 700;
  color: var(--main-color-ours) !important;
}

.mainTxtAprasymasMob1 {
  font-weight: 400;
  color: var(--main-background);
  opacity: 85%;
  margin: 5px;
}

.mainTxtAprasymas {
  font-weight: 400;
  font-size: 14px;
}

.lenteleVidus .prisijungimasLabel.regSek {
  height: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.lenteleVidus .prisijungimasLabel.regSek h5 {
  margin: unset;
}

.pamirsai_pass {
  font-weight: 500;
  font-size: 14px;
  margin: 10px 0 !important;
  padding: unset !important;
  width: 100%;
  display: flex !important;
  justify-content: flex-start !important;
  color: #272727 !important;
  opacity: 85%;
  text-transform: none !important;
  text-decoration: underline !important;
}



.submitButtonCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
  width: 100%;
  justify-content: flex-start;
}

.selectedSignIn {
  opacity: 50%;
}

.login_overflow {
  max-height: 80vh;
  overflow: auto;
}

.checkBoxContainer {
  display: flex;
  flex-direction: column;
}

.prisijungimasLabelMobile {
  display: none;
}

.loginLogo {
  position: absolute;
  right: 14%;
  bottom: 3%;
  z-index: 1000;
  width: 7%;
}

.loginLogo img {
  width: 10%;
}
.loginLogo2 {
  position: absolute;
  right: 3%;
  bottom: 3%;
  z-index: 1000;
  width: 10%;
}

.loginLogo2 img {
  width: 10%;
}

.loginLogoCont {
  cursor: pointer;
}


@media only screen and (max-width: 600px) { 
  .loginLogo {
    position: absolute;
    right: 36%;
    bottom: 3%;
    z-index: 1000;
    width: 20%;
  }
  
  .loginLogo img {
    width: 10%;
  }
  .loginLogo2 {
    position: absolute;
    right: 3%;
    bottom: 3%;
    z-index: 1000;
    width: 30%;
  }
  
  .loginLogo2 img {
    width: 10%;
  }
  
  .loginLogoCont {
    cursor: pointer;
  }}

.mobLogIn {
  width: 100% !important;
  text-transform: capitalize !important;
}

.kalbaSelectLabel {
  display: none !important;
}

.kalbaSelectLabel {
  text-transform: uppercase !important;
}

.kalbaSelect fieldset {
  border: unset !important;
}

.kalbaLogin {
  border: none !important;
  position: absolute;
  top: 2%;
  right: 2%;
  z-index: 1000;
}


.kalbaLogin:focus {
  box-shadow: none;
  outline: none;
}

.kalbaLogin.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.wrongDataTxt {
  font-size: 12px;
  text-align: left;
  color: red;
  width: 100%;
}

.MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 9;
}

.creatormodalTextfields .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.MuiFormLabel-filled.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  z-index: 0;
}

.reg .MuiFormLabel-root.MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.MuiInputLabel-sizeSmall.MuiInputLabel-outlined.MuiFormLabel-colorPrimary.Mui-focused {
  z-index: 10;
}

.txtFieldLabelAlign {
  align-items: center;
}

@media screen and (max-width: 600px) {

  .prisijungimoLangas {
    flex-direction: column;
    position: relative;
  }

  .prisijungimasImg img {
    height: 100vh;
  }

  .lentele {
    width: unset;
    padding: 30px;
    width: unset;
    height: 70%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: flex-start;

  }

  .loginPath {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;

  }

  .loginPath img {
    width: 100%;
  }

  .lenteleVidus {
    width: 90vw;
  }

  .logRegCont {
    justify-content: space-evenly;
  }

  .logReg {
    width: 80%;
    text-align: center;
  }

  .prisijungimasLabelMobile {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }


  .prisijungimasLabel {
    display: none;
  }

  .mainTxt2 {
    color: var(--main-background);
    z-index: 10;
    margin: 5px;
    text-shadow: 2px 2px rgb(39, 39, 39, 0.5);
    width: 95vw;
    font-size: 5vw;
    /* font-weight: ; */
  }

  .mainTxtAprasymasMob {
    color: var(--main-background);
    margin: 5px;
    font-weight: 500;
    color: #272727;
    width: fit-content;
  }

  .submitButtonCont {
    flex-direction: column;
  }

  .mobileRegTxtAlign {
    justify-content: center;
    align-items: center;
  }
}